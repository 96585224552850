//
// 3rd-Party Plugins Stylesheet Includes
//

//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
////  Mandatory Plugins Includes(do not remove or change order!)  ////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
///  Optional Plugins Includes(you can remove or add)  ///////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////


// FormValidation - Best premium validation library for JavaScript. Zero dependencies. Learn more: https://formvalidation.io/
@import "./../../core/plugins/formvalidation/dist/css/formValidation.css";

// Bootstrap Daterangepicker
@import "~bootstrap-daterangepicker/daterangepicker.css";

// select2 - Select2 is a jQuery based replacement for select boxes: https://select2.org/
@import "~select2/dist/css/select2.css";

// Toastr - is a Javascript library for non-blocking notifications. jQuery is required. The goal is to create a simple core library that can be customized and extended: https://github.com/CodeSeven/toastr
@import "~toastr/build/toastr.css";

// Sweetalert2 - a beautiful, responsive, customizable and accessible (WAI-ARIA) replacement for JavaScript's popup boxes: https://sweetalert2.github.io/
@import "~sweetalert2/dist/sweetalert2.css";

// LineAwesome - Replace Font Awesome with modern line icons with a single line of code: https://icons8.com/line-awesome
// @import "~line-awesome/dist/line-awesome/css/line-awesome.css";

// bootstrap-icons
@import "~bootstrap-icons/font/bootstrap-icons.css";

// Fort Awesome. Build and manage icons and typefaces in a single place, then serve them with a single line of code: https://fortawesome.com/
@import "~@fortawesome/fontawesome-free/css/all.min.css";

// Override by custom Metronic style, must be at the end of import
@import "../sass/plugins";
