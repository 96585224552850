//
// DataTables
//


div.dataTables_wrapper div.dataTables_length {
    padding: 0.5rem 0;
}

div.dataTables_wrapper div.dataTables_filter {
    padding: 0.5rem 0;
}

div.dataTables_wrapper div.dataTables_info {
    font-weight: $font-weight-bold;
    color: $gray-700;
    padding: 0.5rem 0;
}

div.dataTables_length + div.dataTables_info {
    margin-left: 1rem;
}

// Pagination
div.dataTables_wrapper div.dataTables_paginate  {
    padding: 0.5rem 0;
    margin-left: 0.5rem;

    .pagination {
        margin: 0;
    }
}

// Sorting
table.dataTable > thead {
    .sorting,
    .sorting_asc,
    .sorting_desc {
        &:before,
        &:after {
            opacity: 0;
            display: inline-block;
            width: 0.75rem;
            height: 0.75rem;
            content: " ";
            top: 50%;
            bottom: auto;
            right: auto;
            left: auto;
            margin-left: 1rem;
            transform: translateX(-50%) translateY(-50%);
        }
    }
}

table.dataTable > thead .sorting_asc:after {
    opacity: 1;
    @include svg-bg-icon(arrow-top, $text-muted);
}

table.dataTable > thead .sorting_desc:after {
    opacity: 1;
    @include svg-bg-icon(arrow-bottom, $text-muted);
}
